import React, { useState } from 'react';
import axios_instance from '../../components/axios'
import { validations } from '../../components/Validations';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext';
import { NotificationManager} from 'react-notifications';
import { Container, Paper, Typography, TextField, Button } from '@mui/material';


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = React.useState({});
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    let validationParams = {
      email,
      password
    };
    let newErrors = validations(validationParams);

    setErrors(newErrors);
    let hasErrors = Object.keys(newErrors).some(x => newErrors[x])
    if (hasErrors) {
      NotificationManager.error("Favor de llenar los datos para iniciar sesion");
    } else {
      try {
        const response = await axios_instance.post('/api/v1/sessions', {
          email,
          password
        });

        const { data } = response;
        login(data.token); // Call login function from AuthContext to set token
        navigate('/'); // Redirect to home page after successful login
      } catch (error) {
        console.error('Login error:', error);
        // Handle login error (e.g., display error message)
      }
    }
  };

  return (
    <Container maxWidth="xs">
      <Paper sx={{ padding: 4, marginTop: 8 }}>
        <form>
          <Typography variant="h4" gutterBottom>
            Login
          </Typography>

          <TextField
            error={errors?.email ? true : false }
            variant='filled'
            margin="normal"
            required
            fullWidth
            id="Email"
            label="Email"
            name="Email"
            autoComplete="Email"
            autoFocus
            style={{background: "#FFF"}}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            error={errors?.password ? true : false }
            variant='filled'
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            style={{background: "#FFF"}}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2}}
            onClick={handleLogin}
            style={{backgroundColor: "#000"}}
          >
            Sign In
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default Login;
