import React, { useState, useEffect } from 'react';
import axios_instance from '../../components/axios'
import CustomizedTable from '../../components/CustomizedTable/CustomizeTable';
import { Container, Typography } from '@mui/material';

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios_instance.get('/api/v1/transactions');
        setTransactions(response.data);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();
  }, []);

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Transactions
      </Typography>
      <CustomizedTable
        headers={['ID', 'Date', 'Type', 'Amount']}
        rows={transactions.map(transaction => ({
          id: transaction.id,
          Date: transaction.date,
          Type: transaction.type,
          Amount: transaction.amount
        }))}
      />
    </Container>
  );
};

export default Transactions;
