import Home from '../views/Home/Home';
import Products from '../views/Products/Products';
import Transactions from '../views/Transactions/Transactions';
import Orders from '../views/Orders/Orders';
import NewOrder from '../views/Orders/NewOrder';
import Clients from '../views/Clients/Clients';
import Login from '../views/Auth/Login';
import AppRoutes from '../components/AppRoutes';

const routes = [
  {
    path: '/login',
    component: <Login />,
    isPrivate: false,
  },
  {
    path: '/',
    component: <AppRoutes ><Home/></AppRoutes>,
    isPrivate: true,
  },
  {
    path: '/products',
    component: <AppRoutes><Products/></AppRoutes>,
    isPrivate: true,
  },
  {
    path: '/orders',
    component: <AppRoutes><Orders/></AppRoutes>,
    isPrivate: true,
  },
  {
    path: '/new-order',
    component: <NewOrder/>,
    isPrivate: false,
  },
  {
    path: '/transactions',
    component: <AppRoutes><Transactions/></AppRoutes>,
    isPrivate: true,
  },
  {
    path: '/clients',
    component: <AppRoutes><Clients/></AppRoutes>,
    isPrivate: true,
  }
]

export default routes;
