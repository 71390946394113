import React from 'react';
import {Navigate} from 'react-router-dom';
import Header from '../Header'
import { useAuth } from '../../Context/AuthContext';


const AppRoutes = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated()) {
    return (
      <Header>
        {children}
      </Header>
    )
  } else {
    return (
      <Navigate to={{pathname: "/login"}} />
    )
  }
}

export default AppRoutes
