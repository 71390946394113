export function validations(fields) {
  let errors = {}
  for (let field in fields) {
    if (fields.hasOwnProperty(field)) {
      switch (typeof fields[field]) {
        case 'string':
          if (fields[field])
            fields[field].length === 0 || fields[field] === "" ? errors[field] = true : errors[field] = false;
          else
            errors[field] = true;
          break;
        case 'number':
          field[field] === null ? errors[field] = true : errors[field] = false;
          break;
        case 'undefined':
          errors[field] = true;
          break;
        default:
          typeof field[field] === 'undefined' ? errors[field] = true : errors[field] = false
      }
    }
  }
  return errors;
}
