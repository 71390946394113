import createPdf from '../CreatePdf';
import logo from '../../img/logoB64'

const generateTicket = async (output, orderInfo) => {
  const body = [
    [
      {
        text: 'CÓDIGO - DESCRIPCIÓN',
        colSpan: 4,
        style: 'tProductsHeader',
      },
      {},
      {},
      {},
    ],
    [
      { text: 'CANT.', style: 'tProductsHeader' },
      {},
      { text: 'PRECIO', style: 'tProductsHeader', alignment: 'right' },
      { text: 'TOTAL', style: 'tProductsHeader', alignment: 'right' },
    ],
  ]
  orderInfo.items.map(item => {
    body.push([
      {
        text: item.name,
        style: 'tProductsBody',
        colSpan: 4,
      },
      {},
      {},
      {},
    ])
    body.push([
      { text: item.quantity, style: 'tProductsBody', alignment: 'center' },
      {},
      { text: item.price, style: 'tProductsBody', alignment: 'right' },
      { text: item.item_total, style: 'tProductsBody', alignment: 'right' },
    ])
  })
  const content = [
    //DATOS EMPRESA
    {
      image: logo(), //Logo
      fit: [141.73, 56.692],
      alignment: 'center',
    },
    { text: 'Yummy Sushi', style: 'header', margin: [0, 10, 0, 0] },
    { text: 'Av Villa Corona 1115, Mitras Poniente, 66023 Villas del Poniente, N.L.', style: 'header' },
    { text: 'RFC (YummySushiRFC)', style: 'header' },

    //TIPO Y NUMERO DOCUMENTO
    { text: 'Teléfono: 81 1339 0524', style: 'header', margin: [0, 10, 0, 2.25] },
    // { text: 'F001-000001', style: 'header', margin: [0, 2.25, 0, 0] },

    //DATOS CEBECERA FACTURAR
    {
      margin: [0, 10, 0, 0],
      table: {
        widths: ['25%', '35%', '15%', '25%'],
        body: [
          [
            { text: 'FECHA:', style: 'tHeaderLabel' },
            { text: orderInfo.date, style: 'tHeaderValue' },
            { text: 'HORA:', style: 'tHeaderLabel' },
            { text: orderInfo.time, style: 'tHeaderValue' },
          ],
          [
            { text: 'PEDIDO:', style: 'tHeaderLabel' },
            { text: orderInfo.folio, style: 'tHeaderValue', colSpan: 3 },
            {},
            {},
          ],
          [
            { text: 'CAJERO:', style: 'tHeaderLabel' },
            { text: 'Andres Cano', style: 'tHeaderValue', colSpan: 3 },
            {},
            {},
          ],
        ],
      },
      layout: 'noBorders',
    },
    //TABLA PRODUCTOS
    {
      margin: [0, 10, 0, 0],
      table: {
        widths: ['20%', '20%', '30%', '30%'],
        headerRows: 2,
        body: body,
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 2 ? 0.5 : 0;
        },
        vLineWidth: function (i, node) {
          return 0;
        },
        hLineColor: function () {
          return '#f2f0f0';
        },
        paddingTop: function (i, node) {
          return i % 2 === 0 ? 10 : 1;
        },
      },
    },
    {
      margin: [0, 10, 0, 0],
      table: {
        widths: ['25%', '35%', '15%', '25%'],
        body: [
          //TOTALES
          [
            { text: 'SUBTOTAL: ', style: 'tTotals', colSpan: 2 },
            {},
            { text: orderInfo.subtotal, style: 'tTotals', colSpan: 2 },
            {},
          ],
          [
            { text: 'IVA(16.00%): ', style: 'tTotals', colSpan: 2 },
            {},
            { text: orderInfo.taxes, style: 'tTotals', colSpan: 2 },
            {},
          ],
          [
            { text: 'TOTAL: ', style: 'tTotals', colSpan: 2 },
            {},
            { text: orderInfo.total, style: 'tTotals', colSpan: 2 },
            {},
          ],
          //TOTAL IMPORTE EN LETRAS
          // [
          //   {
          //     text: 'IMPORTE EN LETRAS:',
          //     style: 'tTotals',
          //     alignment: 'left',
          //     colSpan: 4,
          //     margin: [0, 4, 0, 0],
          //   },
          //   {},
          //   {},
          //   {},
          // ],
          // [
          //   {
          //     text: 'SON: SEISCIENTOS TREINTA MIL QUINIENTOS CINCO Y CINCO CON 00/100 PESOS MEXICANOS',
          //     style: 'tProductsBody',
          //     colSpan: 4,
          //   },
          //   {},
          //   {},
          //   {},
          // ],
          // //FORMAS PAGO
          // [
          //   {
          //     text: 'FORMA DE PAGO:',
          //     style: 'tTotals',
          //     alignment: 'left',
          //     colSpan: 4,
          //     margin: [0, 4, 0, 0],
          //   },
          //   {},
          //   {},
          //   {},
          // ],
          // [{ text: 'CONTADO', style: 'tProductsBody', colSpan: 4 }, {}, {}, {}],
          // [
          //   { text: 'EFECTIVO: S/', style: 'tTotals', colSpan: 2 },
          //   {},
          //   { text: '635.00', style: 'tTotals', colSpan: 2 },
          //   {},
          // ],
          // [
          //   { text: 'VISA: S/', style: 'tTotals', colSpan: 2 },
          //   {},
          //   { text: '635.00', style: 'tTotals', colSpan: 2 },
          //   {},
          // ],
          //DATOS CLIENTE
          // [
          //   {
          //     text: 'CLIENTE: ',
          //     style: 'tTotals',
          //     alignment: 'left',
          //     colSpan: 4,
          //     margin: [0, 6, 0, 0],
          //   },
          //   {},
          //   {},
          //   {},
          // ],
          // [
          //   { text: 'NOMBRES: ', style: 'tClientLabel' },
          //   { text: 'MADERAS CASTOREO S.A.', style: 'tClientValue', colSpan: 3 },
          //   {},
          //   {},
          // ],
          // [
          //   { text: 'DOC.ID: ', style: 'tClientLabel' },
          //   { text: '11155998822', style: 'tClientValue', colSpan: 3 },
          //   {},
          //   {},
          // ],
          // [
          //   { text: 'DIRECC.: ', style: 'tClientLabel' },
          //   {
          //     text: '15Z INT. 7X6 URB. JARDIN - SAN ISIDRO - LIMA',
          //     style: 'tClientValue',
          //     colSpan: 3,
          //   },
          //   {},
          //   {},
          // ],
        ],
      },
      layout: 'noBorders',
    },
  ];

  const response = await createPdf({ content }, orderInfo.folio, output);
  return response;
};

export default generateTicket;
