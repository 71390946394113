import React, { useEffect, useState } from 'react';
import axios_instance from '../../components/axios'
import { Container, Card, CardContent, Typography, Grid, Paper } from '@mui/material';

const Home = () => {
  const [totalOrders, setTotalOrders] = useState(0)
  const [byDay, setByDay] = useState(0)
  const [byWeek, setByWeek] = useState(0)
  const [byMonth, setByMonth] = useState(0)

  useEffect(() => {
    const fetch = async () => {
      const response = await axios_instance.get('/api/v1/orders/dashboard');
      const total_paid = await response.data.total_paid

      setByDay(total_paid.day)
      setByWeek(total_paid.week)
      setByMonth(total_paid.month)
      setTotalOrders(response.data.total_orders)
    }

    fetch()
  }, []);


  return (
    <Container maxWidth="lg" style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Paper style={{ padding: '20px', backgroundColor: '#f0f0f0' }}>
        <Typography variant="h5" gutterBottom>
          Ventas Totales
        </Typography>
        <Grid sx={{flexGrow: 1}} container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="h2">
                  Ordenes Totales
                </Typography>
                <Grid sx={{flexGrow: 1}} container spacing={2}>

                  <Grid item xs={6} md={4}>
                    <Typography variant="body2" component="p">
                      Totales: {totalOrders}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant="body2" component="p">
                      Dia: {byDay.count}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant="body2" component="p">
                      Semana: {byWeek.count}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant="body2" component="p">
                      Mes: {byMonth.count}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="h2">
                  Total Pagado(dia)
                </Typography>
                <Typography variant="body2" component="p">
                  ${byDay.amount} MXN / Ordenes: {byDay.paid_count}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="h2">
                  Total Pagado(semana)
                </Typography>
                <Typography variant="body2" component="p">
                  ${byWeek.amount} MXN / Ordenes: {byWeek.paid_count}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="h2">
                  Total Pagado(mes)
                </Typography>
                <Typography variant="body2" component="p">
                  ${byMonth.amount} MXN / Ordenes: {byMonth.paid_count}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

        </Grid>
      </Paper>
    </Container>
  );
};

export default Home;
