import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import { AuthProvider } from './Context/AuthContext';
import { CartProvider } from './Context/CartContext'
// import PrivateRoute from './components/PrivateRoute';
import 'react-notifications/lib/notifications.css';
import routes from './config/routes';
import { navigation } from './config/history'

function App() {
  return (
    <AuthProvider>
      <CartProvider>
        <HistoryRouter history={navigation}>
          <NotificationContainer />
          <Routes>
            {
              routes.map((route) => (
                <Route path={route.path} key={route.path} exact element={route.component} />
              ))
            }
          </Routes>
        </HistoryRouter>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;
