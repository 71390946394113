import React, { createContext, useContext, useState } from 'react';
import axios_instance from '../components/axios'
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = (token) => {
    localStorage.setItem('headers', JSON.stringify({"Authorization": `Bearer ${token}`})); // Store token in localStorage
    localStorage.setItem('token', token)
    // Decode token and set user information
    const decodedToken = decodeToken(token);
    setUser(decodedToken);
  };

  const logout = async () => {
    const response = await axios_instance.delete('/api/v1/sessions/logout');
    localStorage.removeItem('token'); // Remove token from localStorage
    localStorage.removeItem('headers');
    setUser(null);
  };

  const isAuthenticated = () => {
    return localStorage.getItem('token') !== null;
  };

  const decodeToken = (token) => {
    try {
      const decoded = jwtDecode(token);
      return decoded;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };

  const getUser = () => {
    const token = localStorage.getItem('token')
    const decodedToken = decodeToken(token)
    return decodedToken?.name
  }

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated, getUser }}>
      {children}
    </AuthContext.Provider>
  );
};
