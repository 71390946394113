import React, { useContext } from 'react';
import { CartContext } from '../../Context/CartContext';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';

export default function Cart({ showModal, toggle, createOrder }) {
  const { cartItems, addToCart, removeFromCart, clearCart, getCartTotal, changePrice } = useContext(CartContext);

  const buildOrder = () => {

    let order_products_attributes = cartItems.map((item) => ({
      product_id: item.id,
      quantity: item.quantity,
      description: item.description,
      total: (item.price * item.quantity)
    }))

    let order = {
      total: getCartTotal(),
      order_products_attributes
    }

    createOrder(order)
  }

  return (
    <Dialog open={showModal} onClose={toggle}>
      <DialogTitle style={{display: 'flex', minWidth: 400, justifyContent: 'center', background: '#872023', color: '#fff'}}>
        <Typography variant="h4" component="div">
          Orden
        </Typography>
        <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
          <Button onClick={toggle} >
            <CloseIcon style={{color: "#fff"}}/>
          </Button>
        </div>
      </DialogTitle>
      <DialogContent style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
        {cartItems.map((item) => (
          <div key={item.id} style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{display: 'flex', justifyContent: 'flex-start'}}>
              <img src={item.thumbnail} alt={item.title} className="rounded-md h-24" />
              <div>
                <Typography variant="h6">{item.name}</Typography>
                <TextField
                    type="number"
                    value={item.price}
                    onChange={(e) => changePrice(item, e.target.value)}
                    InputProps={{ inputProps: { min: 1 } }}
                    sx={{ maxWidth: 100 }}
                  />
              </div>
            </div>
            <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
              <Button
                style={{ background: '#000', color: '#fff', }}
                sx={{minWidth: 40, maxHeight: 40}}
                onClick={() => addToCart(item)}
              >
                <AddIcon />
              </Button>
              <Typography style={{padding: 10}}>{item.quantity}</Typography>
              <Button
                style={{ background: '#000', color: '#fff', }}
                sx={{minWidth: 40, maxHeight: 40}}
                onClick={() => removeFromCart(item)}
              >
                <RemoveIcon />
              </Button>
            </div>
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        {cartItems.length > 0 ? (
          <div>
            <Typography variant="h6">Total: ${getCartTotal()}</Typography>
            <div style={{display: 'flex'}}>

              <div style={{padding: 10}}>
                <Button variant="contained" style={{ background: '#000', color: '#fff', }} onClick={clearCart}>
                  Limpiar Orden
                </Button>
              </div>
              <div style={{padding: 10}}>
                <Button variant="contained" style={{ background: '#000', color: '#fff', }} onClick={buildOrder}>
                  Guardar Orden
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <Typography variant="h6">Your cart is empty</Typography>
        )}
      </DialogActions>
    </Dialog>
  );
}
