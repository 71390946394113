import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { NavLink, useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo from '../../img/yumisushi.png'
import avatar from '../../img/avatar.png'
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SummarizeIcon from '@mui/icons-material/Summarize';
import InventoryIcon from '@mui/icons-material/Inventory';
import GroupIcon from '@mui/icons-material/Group';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { SvgIcon } from '@material-ui/core';

const routes = [
  {name: 'Dashboard', url: '/', icon: SummarizeIcon},
  {name: 'Orders', url: '/orders', icon: ListAltIcon},
  {name: 'Products', url: '/products', icon: InventoryIcon},
  {name: 'Clients', url: '/clients', icon: GroupIcon},
]

const drawerWidth = 180;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const muiButtonTheme = createTheme({
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          paddingLeft: '20px',
          paddingRight: '20px'
        }
      }
    },
    IconButton: {
      styleOverrides: {
        root: {
          paddingLeft: '20px',
          paddingRight: '20px'
        }
      }
    }
  }
})

function ResponsiveAppBar(props) {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  let history = useNavigate();

  React.useEffect(() => {
    if(window !== 'undefined'){
      const storedopen = localStorage.getItem('draweropen') === 'true'
      localStorage.setItem('draweropen', storedopen);
      setOpen(storedopen)
    }
  }, [])

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

   const handleDrawer = () => {
     if(!open) {
      setOpen(true);
      localStorage.setItem('draweropen', true);
    } else {
      setOpen(false);
      localStorage.setItem('draweropen', false);
    }
  };

  const sendToReports = () => {
    history('/')
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" style={{background: '#872022', color: 'white'}}>
        <Container maxWidth="xxl">

          <Toolbar disableGutters>
            <Box sx={{display: { xs: 'flex', md: 'flex' }, color: 'white' }}>
              <ThemeProvider theme={muiButtonTheme}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleDrawer}
                  color="white"
                  style={{paddingLeft: 0, paddingRight: 10}}
                >
                  <MenuIcon sx={{ color: 'white'}}/>
                </IconButton>
              </ThemeProvider>
            </Box>
            <Box onClick={sendToReports} component="img" sx={{ cursor: 'pointer', width:65}} alt="Logo" src={logo} />

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0}}>
                  <Avatar alt="Remy Sharp" src={avatar} />
                  <Typography textAlign="center" color="white" sx={{ p: 2}}>{props.userName}</Typography>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px', display: 'flex', flexDirection: 'column'}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={props.logout}>
                  <Typography textAlign="center">Cerrar Sesión</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer variant="permanent" open={open} PaperProps={{sx: { backgroundColor: '#872022 !important', color: 'white'}}}>
        <DrawerHeader />
        <List>
          {routes.map((route) => (
            <ListItem disablePadding key={route.name} sx={{ display: 'block' }}>
              <NavLink
                to={route.url}
                style={{color: 'white'}}
              >
                <ThemeProvider theme={muiButtonTheme}>
                  <ListItemButton
                    classes={{ root: 'PoinmexButtonBase-root'}}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        color: 'white'
                      }}
                    >
                      {<SvgIcon component={route.icon} inheritViewBox />}
                    </ListItemIcon>
                    <ListItemText primary={route.name} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ThemeProvider>
              </NavLink >
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        {props.children}
      </Box>
    </Box>
  );
}

export default ResponsiveAppBar;
