import { createContext, useState, useEffect } from 'react'
import { NotificationManager } from 'react-notifications';
import promos from '../config/promos'

export const CartContext = createContext()

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [])

  const addToCart = (item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.id === item.id);
    let newCartItems
    // if (isItemInCart) {
    //   newCartItems = cartItems.map((cartItem) =>
    //     cartItem.id === item.id
    //       ? { ...cartItem, quantity: cartItem.quantity + 1 }
    //       : cartItem
    //   )
    // } else {
    newCartItems = [...cartItems, { ...item, quantity: 1 }]
    // }
    setCartItems(newCartItems);
    let proms = promos()
    if (proms.includes(item.category_code)) {
      buildPromos(newCartItems)
    }
    NotificationManager.success(`${item.name} ha sido agregado a la orden`, "", 3000);
  };

  const removeFromCart = (item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.id === item.id);

    if (isItemInCart.quantity === 1) {
      setCartItems(cartItems.filter((cartItem) => cartItem.id !== item.id));
    } else {
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.id === item.id
            ? { ...cartItem, quantity: cartItem.quantity - 1 }
            : cartItem
        )
      );
    }
  };

  const clearCart = () => {
    setCartItems([]);
  };

  const getCartTotal = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const changePrice = (item, price) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.id === item.id);

    if (isItemInCart) {
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.id === item.id
            ? { ...cartItem, price: price }
            : cartItem
        )
      );
    }
  }

  const buildPromos = (newCartItems) => {
    let sortedItems = []
    let promoItems = newCartItems.filter(item => {
      return promos().includes(item.category_code)
    })
    if(promoItems.length >= 2) {
      sortedItems = promoItems.sort((a, b) => parseFloat(a.real_price) - parseFloat(b.real_price))
      let arrays = split(sortedItems, sortedItems.length)
    }
  }

  const split = (arr, n) => {
    let result = []
    let half = Math.floor(n / 2)
    let divide = n % 2 === 0 ? half : half += 1;
    result.push(arr.slice(0, divide))
    result.push(arr.slice(divide, n))
    half = n % 2 === 0 ? half : half -= 1;
    let secondI = half
    for (let i = 0; i < half; i++) {
      secondI -= 1
      result[0][i].price = 0
      result[1][secondI].price = result[1][secondI].category_price
    }
    let newArr = [...result[0], ...result[1]]
    return newArr;
}

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    const cartItems = localStorage.getItem("cartItems");
    if (cartItems) {
      setCartItems(JSON.parse(cartItems));
    }
  }, []);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        getCartTotal,
        changePrice
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
