import * as React from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext'
import ResponsiveAppBar from '../Navbar'

export default function Header(props) {
  const [currentUserName, setCurrentUserName] = React.useState('')
  const {getUser, logout } = useAuth();
  let history = useNavigate();

  React.useEffect(() => {

    const user = getUser()

    setCurrentUserName(user)
  }, [getUser])

  const handleLogout = () => {
    logout();
    history('/login')
  }

  return (
    <ResponsiveAppBar
      userName={currentUserName}
      logout={handleLogout}
      children={props.children}
    />
  )
}
