import { useCallback, useState } from 'react';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { pdfjs, Document, Page } from 'react-pdf';
import { PDFDocument } from 'pdf-lib';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './Sample.css';


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const resizeObserverOptions = {};

const maxWidth = 800;


export default function Sample() {
  const [file, setFile] = useState('./sample.pdf');
  const [numPages, setNumPages] = useState();
  const [pageWidth, setPageWidth] = useState();
  const [pageHeight, setPageHeight] = useState();
  const [pdf, setPdf] = useState();
  const [containerRef, setContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState();
  const [pdfScale, setPdfScale] = useState(1.0)

  const onResize = useCallback((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  const onFileChange = async (event) => {
    const { files } = event.target;

    if (files && files[0]) {
      let fileBuffer = await files[0].arrayBuffer()
      const pdfDoc = await PDFDocument.load(fileBuffer)
      setPdf(pdfDoc)
      setFile(files[0] || null);
    }
  }

  function onDocumentLoadSuccess(documentPdf) {
    const pages = pdf.getPages()
    const firstPage = pages[0]
    const { width, height } = firstPage.getSize()
    console.log("width", (width * 0.352777778) * 3.7795275591)
    console.log("height", (height * 0.352777778) * 3.7795275591)
    setPageWidth((width * 0.352777778) * 3.7795275591);
    setPageHeight((height * 0.352777778) * 3.7795275591);
    setNumPages(documentPdf.numPages);
  }

  function handleZoominIcon() {
    setPdfScale(prev => {
      if (prev > 2.1) {
        return 2.2;
      } else {
        return prev + 0.1;
      }
    });
  }

  function handleZoomoutIcon() {
    setPdfScale(prev => {
      if (prev <= 0.5) {
        return 0.5;
      } else {
        return prev - 0.1;
      }
    });
  }

  const handlePrint = (event) => {
    event.preventDefault();
    window.open(file, "PRINT", "height=400,width=600");
  };

  return (
    <div className="Example">
      <header>
        <h1>react-pdf sample page</h1>
      </header>
      <div className="Example__container">
        <div className="Example__container__load">
          <label htmlFor="file">Load from file:</label>{' '}
          <input onChange={onFileChange} type="file" />
          <button onClick={handleZoomoutIcon}>
            -
          </button>
          <button onClick={handleZoominIcon}>
            +
          </button>
          <button onClick={handlePrint}>
            PRINT
            </button>
        </div>
        <div className="Example__container__document" ref={setContainerRef}>
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
            {Array.from(new Array(numPages), (el, index) => (
              <div style={{width: pageWidth, height: pageHeight, background: "#fff", marginBottom: 10}}>
                <Page
                  scale={pdfScale}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                />
              </div>
            ))}
          </Document>
        </div>
      </div>
    </div>
  );
}
