// CustomizedTable.js
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';

import { Container, InputAdornment, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState, useEffect } from 'react'
import { DateRangePicker } from "mui-daterange-picker";
import Button from '@mui/material/Button';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#872022',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles({
  root: {
    '&$active': {
      color: "white",
    },
    '&$icon': {
      color: 'white',
    },
  },
  active: {}, // pseudo
  icon: {},
});

function EnhancedTableHead(props) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort, columns } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((header) => (
          <StyledTableCell
            key={header.id}
            align={header.numeric ? 'right' : 'left'}
            padding={header.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === header.id ? order : false}
            style={{color: 'white'}}
            sx={{color: 'white'}}
          >
            {
              header.id === "actions" ? (
                <>
                  {header.label}
                </>
              ) :(
                <TableSortLabel
                  active={orderBy === header.id}
                  direction={orderBy === header.id ? order : 'asc'}
                  onClick={createSortHandler(header.id)}
                  style={{color: 'white'}}
                  classes={{ root: classes.root, active: classes.active, icon: classes.icon }}
                >
                  {header.label}
                  {/* {orderBy === header.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null} */}
                </TableSortLabel>
              )
            }

          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function CustomizedTable(props){
  const [searched, setSearched] = useState("");
  const [rows, setRows] = useState([])
  const [order, setOrder] = useState(props.order);
  const [orderBy, setOrderBy] = useState(props.orderBy);
  const [selected, setSelected] = useState([]);

  const [dateRange, setDateRange] = useState({});
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [destroyRow, setDestroyRow] = useState();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc'
    setOrder(newOrder);
    setOrderBy(property);
    props.handleSorting({property, newOrder})
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSearch = event => {
    setSearched(event.target.value)
    props.search(event.target.value)
  };

  const newResource = event => {
    props.newResource()
  }

  useEffect(() => {
    setRows([])
    setRows([...props.rows])
  }, [props.rows])

  const toggle = () => setOpen(!open);

  const handleDestroyRow = (e, row) => {
    setDestroyRow(row)
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleDestroy = (e) => {
    props.destroy(e, destroyRow);
    handleCloseDialog();
    setDestroyRow();
  }

  return (
    <>
      <div style={{flex: 1, display: 'flex', flexDirection: 'row'}}>
        {props.search &&
          <Container maxWidth="md" sx={{ mt: 1, ml: -3, mb: 2 }}>
            <TextField
              id="search"
              type="search"
              label="Search"
              value={searched}
              onChange={handleSearch}
              variant="filled"
              sx={{ width: 200, backgroundColor: 'white'}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Container>
        }
        {
          props.dateFilter && (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <Button onClick={toggle} variant="contained" color="primary">{dateRange[0] ? dateRange[0] - dateRange[1] : "Rango de Fechas"}</Button>
              <DateRangePicker
                style={{width: 200, height: 200}}
                open={open}
                toggle={toggle}
                onChange={(range) => setDateRange(range)}
              />
            </div>
          )
        }
        {props.newResource && (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              <Button onClick={newResource} variant="contained" style={{alignSelf: 'center', background: "#000"}}>New</Button>
            </div>
          )
        }
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            columns={props.headers}
          />
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.id}>
                {
                  Object.keys(rows[0]).map((key) => {
                    if(key !== "actions") {
                      return (
                        <StyledTableCell key={key}>{row[key]}</StyledTableCell>
                      )
                    } else {
                      return (
                        <StyledTableCell key={key} align="right">
                          {row[key].includes("Mostrar") && <span style={{ cursor: 'pointer' }} onClick={(e) => props.show(e, row)}>Mostrar </span>}
                          {row[key].includes("Editar") && <span style={{ cursor: 'pointer' }} onClick={(e) => props.edit(e, row)}>Editar </span>}
                          {row[key].includes("Preparar") && <span style={{ cursor: 'pointer' }} onClick={(e) => props.edit(e, row)}>Preparar </span>}
                          {row[key].includes("Pagar") && <span style={{ cursor: 'pointer' }} onClick={(e) => props.edit(e, row)}>Cobrar </span>}
                          {<span style={{ cursor: 'pointer' }} onClick={(e) => props.printTicket(e, row)}>Ticket </span>}
                          {row[key].includes("Destruir") && !row.status === "Pagado" ? (<span style={{ cursor: 'pointer' }} onClick={(e) => handleDestroyRow(e, row)}>Destruir</span>): null}
                          {row[key].includes("Aprobar") && (
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                              <Button variant="outlined" sx={{fontSize: 12, display: 'flex', mr: 1}} color="success" onClick={(e) => props.approve(e, row)}>Aprobar</Button>
                              <Button variant="outlined" sx={{fontSize: 12, display: 'flex'}} color="error" onClick={(e) => props.reject(e, row)}>Rechazar</Button>
                            </div>
                          )}
                        </StyledTableCell>
                      )
                    }
                  })
                }
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¿Estas Seguro de Eliminar este elemento ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant="contained" style={{alignSelf: 'center', background: "#000"}}>No</Button>
          <Button onClick={handleDestroy} variant="contained" style={{alignSelf: 'center', background: "#000"}}>
            Si destruir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
