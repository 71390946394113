import React, { useEffect, useState } from 'react';
import axios_instance from '../../components/axios'
import { useAuth } from '../../Context/AuthContext';
import { Container, Typography} from '@mui/material';
import CustomizedTable from '../../components/CustomizedTable/CustomizeTable'; // Assuming CustomizedTable.js is located in the components folder
import { useNavigate } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import ticket from '../../components/TicketGenerator';

// const headers = ["Folio", "Total", "Status", "Fecha Creacion", "Acciones"]; // Define table headers
const headers = [
  {
    id: 'folio',
    numeric: false,
    disablePadding: false,
    label: "Folio"
  },
  {
    id: 'total',
    numeric: false,
    disablePadding: false,
    label: "Total"
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: "Estatus"
  },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
    label: "Fecha de Creacion"
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: "Acciones"
  },
];

const Orders = () => {
  const { user } = useAuth();
  const [orders, setOrders] = useState([{id: 0, totalAmount: 0}]);
  const [query, setQuery] = useState("");
  const [property, setProperty] = useState("created_at")
  const [order, setOrder] = useState("desc")
  const [rows, setRows] = useState([{
    "Folio": "Cargando",
    "Status": "Cargando",
    "Total": "Cargando",
    "Fecha Creacion": "Cargando",
    actions: ["Mostrar", "next_status", "Destruir"] // Example actions (you can customize this)
  }])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  let history = useNavigate();

  useEffect(() => {
    fetchOrders();
  }, [user, rowsPerPage, page, order, property]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchOrders = async () => {
    let params = {
      page: page + 1,
      pageIndex: page,
      limit: rowsPerPage,
      query: query,
      attribute: property,
      order: order
    }
    try {
      const response = await axios_instance.get('/api/v1/orders', {params});
      const processedData = await response.data.results.map((order) => {
        return {
          folio: order.folio,
          total: order.total,
          status: order.status,
          created_at: order.created_at,
          actions: ["Mostrar", order.next_status, "Destruir"]
        }
      })
      setTotalRecords(response.data.info["total"])
      setOrders(response.data.results);
      setRows(processedData)
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const handleSort = (sorting) => {
    setProperty(sorting.property)
    setOrder(sorting.newOrder)
  }


  const handleSearch = (searchTerm) => {
    setQuery(searchTerm)
  };

  const newOrder = () => {
    history('/products');
  }

  const updateStatus = async (e, row) => {
    const order = orders.find(o => o.folio === row.folio)
    if(order.status === "Pagado" || order.status === "Paid") {
      generateTicket(order)
    } else {
      try {
        let params = {
          id: order.id,
          next_status: order.next_status
        }
        const response = await axios_instance.put(`/api/v1/orders/${order.id}/next_status`, params)
        if(response) {
          if(order.next_status === "Pagar" || order.next_status === "Pay") {
            generateTicket(order)
          }
          fetchOrders()
        }
      } catch (error) {
        console.error('Error Updating order Status: ', error)
      }
    }
  }

  const generateTicket = async (order) => {
    let [date, time] = order.created_at.split(" ")
    let taxes = order.total * 0.16
    let subtotal = order.total - taxes
    let orderInfo = {
      folio: order.folio,
      date: date,
      time: time,
      total: order.total,
      subtotal: subtotal,
      taxes: taxes,
      items: order.order_products.map(item => ({name: item.product, quantity: item.quantity, item_total: item.total, price: item.price}))
    }

    await ticket('print', orderInfo);
  }

  const showOrder = (e, order) => {

  }

  const destroyOrder = (e, order) => {

  }

  const printTicket = (e, row) => {
    const order = orders.find(o => o.folio === row.folio)
    generateTicket(order)
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Ordenes
      </Typography>
      <CustomizedTable
        headers={headers}
        rows={rows}
        search={handleSearch}
        dateFilter={false}
        orderBy={property}
        order={order}
        newResource={newOrder}
        handleSorting={handleSort}
        edit={updateStatus}
        show={showOrder}
        destroy={destroyOrder}
        printTicket={printTicket}
      />
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
};

export default Orders;
