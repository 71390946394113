import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { navigation } from '../config/history'

let headers = JSON.parse(localStorage.getItem("headers")) ||  { 'Authorization': 'Bearer ', locale: "es" }
let isPost = false
const BaseURL = window.location.host === "localhost:3000" ? "http://localhost:3001" : "https://pos.poinmex.com/";
// const BaseURL = "http://192.168.0.111:3001"
// const BaseURL = "https://pos.poinmex.com"

export const axios_instance = axios.create({
  baseURL: BaseURL,
  timeout: 60000,
  headers,
})

export const setAuthorization = (headers, data) => {
  if(headers['Authorization'] || data["token"]) {
    let token = headers['Authorization'] || data["token"]
    let newHeaders = { Authorization: `Bearer ${token}`, locale: "es" }
    axios_instance.defaults.headers = newHeaders
    localStorage.setItem('headers', JSON.stringify(newHeaders))
  }
}

axios_instance.interceptors.request.use((config) => {
  if(config.method === "post" || config.method === "put" || config.method === "delete")
    isPost = true;
  return config;
}, (error) => {
  isPost = false;
  return Promise.reject(error);
});

// Add a response interceptor
axios_instance.interceptors.response.use((response) => {
  // Do something with response data
  if(isPost)
  {
    // NotificationManager.success("Operación Exitosa", "", 3000);
    isPost = false;
  }
  setAuthorization(response.headers, response.data);
  return response;
}, (error) => {
  // Do something with response error
  isPost = false;
  if(error.code === "ECONNABORTED")
  {
    NotificationManager.error("No se pudo contactar al servidor. Vuelva a intentar", "Timeout", 3000);
    // loadHeader();
  }
  if(error.response)
  {
    if(error.response.status === 500)
    {
      // Server error
      setAuthorization(error.response.headers);
      NotificationManager.error("Hubo un error de nuestra parte. Vuelta a intentar si el error persiste, favor de notificar al equipo de desarrollo", "Error Servidor", 5000);
    }
    if(error.response.status === 401)
    {
      // Unauthenticated
      NotificationManager.error('Favor de iniciar sesión', "Sin Autenticación", 3000);
      navigation.replace('/login')
      localStorage.removeItem("headers");
      localStorage.removeItem("currentUser");
      // loadHeader();
    }
    if(error.response.status === 403)
    {
      // Unauthorized
      setAuthorization(error.response.headers);
      NotificationManager.error(error.response.data.message, "Sin Autorización", 3000);
      // loadHeader();
    }
    if(error.response.status === 404)
    {
      //Not found
      setAuthorization(error.response.headers);
      if(error.response.data && error.response.data.message) {
        NotificationManager.error(error.response.data.message, "No Encontrado", 3000);
      } else {
        NotificationManager.error("", "No Encontrado", 3000);
      }
    }
    if(error.response.status === 422)
    {
      setAuthorization(error.response.headers);
      if(error.response.data.errors)
      {
        if (error.response.data.errors.full_messages) {
          error.response.data.errors.full_messages.map((error) => {
            NotificationManager.error(error, 'Error', 3000);
            return true;
          })
        } else {
          error.response.data.errors.map((error) => {
            NotificationManager.error(error, 'Error', 3000);
            return true;
          })
        }
      }
    }
    if(error.response.status === 502)
    {
      setAuthorization(error.response.headers);
      let response = error.response.data.data
      if(response.attributes.errors)
      {
        response.attributes.errors.map((error) => {
          NotificationManager.error(error, `Error ${response.attributes.code}`, 3000);
          return true;
        })
      }
    }
  }
  return Promise.reject(error);
});

export default axios_instance;
