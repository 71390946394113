import React, { useState, useEffect } from 'react';
import axios_instance from '../../components/axios'
import { Container, Typography } from '@mui/material';
import CustomizedTable from '../../components/CustomizedTable/CustomizeTable';

const headers = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: "Id"
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: "Nombre"
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: "Correo"
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: "Telefóno"
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: "Acciones"
  },
];

const Clients = () => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios_instance.get('/api/v1/clients');
        setClients(response.data.results);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchClients();
  }, []);

  const handleSearch = (searchTerm) => {
    // Implement search functionality if needed
    return clients
  };

  const newClient = () => {

  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Clients
      </Typography>
      <CustomizedTable
        headers={headers}
        rows={[{
          id: "Cargando",
          name: "Cargando",
          email: "Cargando",
          phone: "Cargando",
          actions: ["Mostrar", "Editar", "Destruir"]
        }]}
        search={handleSearch}
        newResource={newClient}
      />
    </Container>
  );
};

export default Clients;
