import React, { useState, useEffect, useContext } from 'react';
import axios_instance from '../../components/axios'
import Cart from '../../components/Cart'
import { CartContext } from '../../Context/CartContext'
import { NotificationManager } from 'react-notifications';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Products = () => {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false)

  const { cartItems, addToCart, clearCart } = useContext(CartContext)

  useEffect(() => {
    fetchProducts();
  }, []);

  const toggle = () => {
    setShowModal(!showModal)
  }

  const fetchProducts = async () => {
    try {
      const response = await axios_instance.get('/api/v1/products');
      response.data.map((product) => product["quantity"] = 0)
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const groupedData = products.reduce((acc, curr) => {
    if (!acc[curr.category_name]) {
      acc[curr.category_name] = [];
    }
    acc[curr.category_name].push(curr);
    return acc;
  }, {});

  const createOrder = async (order) => {
    toggle()
    try {
      let response = await axios_instance.post('/api/v1/orders', {order});
      let folio = await response.data.folio
      clearCart()
      NotificationManager.success(`Orden con folio: ${folio} generada exitosamente`, "", 5000);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }

  return (
    <Container maxWidth="lg">
      <Container style={{display: 'flex', flex: 1, paddingBottom: 10, paddingTop: 10}}>
        <Typography variant="h4" gutterBottom sx={{display: 'flex'}}>
          Products
        </Typography>
          {
            !showModal && (
              <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
                  <Button
                  variant="contained"
                  style={{color: '#fff', background: '#000'}}
                  onClick={toggle}
                  sx={{display: 'flex'}}
                >
                  Orden ({cartItems.length} Items)
                </Button>
              </div>
            )
          }
      </Container>
      <Container>
        {
        Object.keys(groupedData).map((category, index) => (
          <Accordion key={index} sx={{ background: '#872023', color: '#FFF', width: 'auto' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: '#FFF' }}/>}
              aria-controls="panel1-content"
              id='panel1-header'
            >
              <b>{category}</b>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                {groupedData[category].map((product, ind) => (

                  <Grid item xs={12} sm={6} md={4} key={product.id}>
                    <Card sx={{ mb: 2, minHeight: 260, display: "flex", flexDirection: "column"}}>
                      <CardContent>
                        <Typography variant="h6" gutterBottom>
                          <b>{product.name}</b>
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          {product.description}
                        </Typography>
                        <Typography variant="h6" color="#000">
                          <b>${product.price}</b>
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ marginTop: 'auto', display: 'flex'}}>
                        <Button
                          variant="outlined"
                          style={{ background: '#872023', color: '#fff' }}
                          onClick={() => addToCart(product)}
                          sx={{p: 1}}
                        >
                          Agregar a la Orden
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </AccordionDetails>
      </Accordion>
      ))}
      </Container>
      <Cart showModal={showModal} toggle={toggle} createOrder={createOrder}/>
    </Container>
  );
};

export default Products;
